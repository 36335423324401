/*For smaller screen sizes*/
@media only screen and (max-width : 768px) {
    .contact.col-container {
        flex-direction: column-reverse;
    }

    .contact .col-one p {
        text-align: center;
        width: 100%;
    }

    .contact .col-one a {
        text-align: center;
        width: ;
    }

}
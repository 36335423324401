.project-title {
    margin: 3em 3% 1em 3%;
}

.project-page h3 {
    margin: .5em 3%;
}

.project-page a {
    font-size: .9em;
    margin: .5em 3% 1em 3%;
}

.col-container {
    overflow: visible;
    height: 100%;
}

.project-description {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
}

.project-page figure {
    margin: 1em 0;
}

.hidden {
    display: none;
}


/*For smaller screen sizes*/
@media only screen and (max-width : 768px) {
    .project-description {
        position: relative;
    }
}

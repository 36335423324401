/* Header Section */
header {
    width: 100%;
    padding: 5em 4em 3em 4em;
    display: flex;
    justify-content: space-between;
    max-width: 65rem;
    margin: auto;
    overflow: hidden;
    font-size: .9em;
}

header a {
    border-bottom: none;
    width: fit-content;
    padding: 0;
}

.logo {
    fill: var(--blue-grey);
    width: 75%;
}

.logo:hover {
    fill: var(--orange);
}

header h2 {
    color: var(--blue-grey);
    font-size: 1.25em;
    margin: 0 0 1.5em 1em;
}

header .main-nav {
    font-size: 1.15em;
    display: flex;
}

header .main-nav .menu-icon, header .main-nav .close-icon {
    display: none;
}

header .main-nav ul {
    list-style-type: none;
    color: var(--black);
    font-weight: bold;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}

header .main-nav ul li {
    position: relative;
    margin: .5em 1em;
}

header .main-nav ul ul {
    flex-direction: column;
    display: none;
}

header .main-nav a {
    font-weight: bold;
}

header .main-nav a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 1em;
    left: 1.5em;
    border-radius: 50%;
    z-index: -10;
}

header .main-nav a:hover::after {
    content: "";
    position: absolute;
    width: 6em;
    height: 6em;
    bottom: -2.2em;
    left: -1.075em;
    background-color: var(--pink);
    border-radius: 50%;
    z-index: -10;
    transition: all 0.2s ease-in-out 0s;
}

header a.main-nav-active-link::after {
    content: "";
    position: absolute;
    width: 6em;
    height: 6em;
    bottom: -2.2em;
    left: -1.075em;
    background-color: var(--orange);
    border-radius: 50%;
    z-index: -10;
}

header a.main-nav-active-link, header a.main-nav a:hover {
    color: white;
}

header .main-nav a.about-link:hover::after {
    width: 6em;
    height: 6em;
    bottom: -2.2em;
    left: -.65em;
}

header a.main-nav-active-link.about-link::after {
    content: "";
    position: absolute;
    width: 6em;
    height: 6em;
    bottom: -2.2em;
    left: -.65em;
    background-color: var(--orange);
    border-radius: 50%;
    z-index: -10;
}

header .main-nav a.portfolio-link:hover::after {
    width: 6em;
    height: 6em;
    bottom: -2.2em;
    left: -.95em;
}

header a.main-nav-active-link.portfolio-link::after {
    width: 6em;
    height: 6em;
    bottom: -2.2em;
    left: -.95em;
}

.ampersand {
    color: var(--orange);
}



/*For small screen sizes*/
@media only screen and (max-width : 900px) {
    header {
        font-size: .78em;
    }

    header ul li {
        padding: 0;
    }
}


@media only screen and (max-width : 765px) {
    header {
        flex-direction: column;
    }

    .App-header {
        padding: 2em 6% 0 6%;
    }

    header .main-nav .menu-icon {
        display: block;
        position: absolute;
        top: 4em;
        right: 1.75em;
        font-size: 1.5em;
    }

    .dark-theme header .main-nav .menu-icon {
        color: white;
    }


    header .main-nav ul {
        display: none;
    }

    header .main-nav ul {
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-end;
    }

    header .main-nav ul li {
        position: relative;
        margin: .5em 1em;
    }

    header .logo {
        margin: auto;
        width: 45%;
    }

    header h2 {
        font-size: .9em;
    }

    /*Removing circle styling effects in nav*/
    header .main-nav a::after,
    header .main-nav a:hover::after,
    header a.main-nav-active-link::after,
    header .main-nav a.about-link:hover::after,
    header a.main-nav-active-link.about-link::after,
    header .main-nav a.portfolio-link:hover::after,
    header a.main-nav-active-link.portfolio-link::after {
        content: "";
        position: absolute;
        Width:0;
        height: 0;
        border-radius: 0;
    }

    header a.main-nav-active-link {
        border-bottom: 4px solid var(--orange);
    }

    .light-theme header a.main-nav-active-link, header a.main-nav a:hover {
        color: var(--blue-grey);
    }

    .dark-theme header a.main-nav-active-link, header a.main-nav a:hover {
        color: white;
    }

    header .main-nav.nav-open {
        position: absolute;
        width: 75%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: var(--blue-grey);
        padding: 3em 1em;
    }

    header .main-nav.nav-open ul {
        display: flex;
        top: 0;
        align-self: flex-start;
    }

    header .main-nav.nav-open ul ul {
        display: none;
    }

    header .main-nav.nav-open ul a {
        color: white;
    }

    .dark-theme header .main-nav.nav-open {
        background-color: white;
    }

    .dark-theme header .main-nav.nav-open ul a, .dark-theme header .main-nav.nav-open .close-icon {
        color: var(--blue-grey);
    }

    header .main-nav.nav-open .menu-icon {
        display: none;
    }

    header .main-nav.nav-open .close-icon {
        display: block;
        color: white;
        position: absolute;
        top: 1.5em;
        right: 1.5em;
    }
}


/* Apply a natural box layout model to all elements, but allowing components to change
Credit: Paul Irish
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}


/* GLOBAL STYLES */
:root {
  --orange: #F05424;
  --pink: #EEC4CE;
  --yellow: #FBDB56;
  --blue-grey: #263d45;
  --cream: #FBEDCC;
  --black: #000;
}

body {
  font-family: Raleway, sans-serif;
  letter-spacing: .025em;
  font-size: 1em;
  position: relative;
  line-height: 1.45;
  color: var(--blue-grey);
}

.background{
  width: 150%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;

}

.text-blurb {
  padding: .35em 0;
}

a {
  text-decoration: none;
  color: var(--blue-grey);
  border-bottom: 2px solid var(--orange);
  font-weight: 500;
}

/* Dark Theme Styles */

.dark-theme {
  background-color: var(--blue-grey);
  color: white;
}

.dark-theme a, .dark-theme p, .dark-theme h2, .dark-theme h3, .dark-theme h4 {
  color: white;
}

.dark-theme .logo {
  fill: white;
}

.light-theme {
  background-color: white;
  color: var(--blue-grey);
}

/* Main Section */
main {
  max-width: 63rem;
  z-index: 0;
  margin: 10% auto 20em auto;
  padding: 0 3em;
}

.col-container {
  display: flex;
  position: relative;
}

.col {
  margin: 3%;
  flex-basis: 50%;
}

.col-twothirds {
  flex-basis: 66%;
}

.col-onethird {
  flex-basis: 33%;
}

.col-threequarters {
  flex-basis: 75%;
}

.col-onequarter {
  flex-basis: 25%;
}




/*For smaller screen sizes*/
@media only screen and (max-width : 768px) {

  html {
    width: fit-content;
  }

  .project-preview img {
    width: 100%;
  }

  main {
    padding: 1em 2em 5em 2em;
    margin: 0;
  }

  .col-container {
    flex-direction: column;
  }

  .col-container figure {
    margin: 1em 0;
  }
}

/* For Smartphones
@media only screen and (max-device-width : 320px) {
  .main {
    max-width: 100%;
  }
  .project-preview {
  }}
  */




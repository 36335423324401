/*Portfolio Section*/
.gallery {
    display: flex;
    flex-direction: column;
}

.gallery a {
    border-bottom: none;
}

.project-preview {
    margin: 1%;
    position: relative;
    flex-basis: 30%;
}

.project-preview figcaption {
    display: none;
    height: 100%;
    width: 100%;
}

.project-preview:hover figcaption {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    background-color: rgba(255,255,255,.75);
    text-align: center;
}

.project-preview figcaption .caption-container {
    margin: auto;

}

.project-preview h2 {
    font-size: 1.4em;
}

.project-preview h3 {
    font-size: 1.1em;
}

.project-img {
    width: 100%;
}


/*For small screens*/
@media only screen and (max-width : 765px) {
    .gallery {
        flex-flow: row wrap;
    }

    .project-preview {
        flex-basis: 48%;
    }

    .project-preview:hover figcaption {
        padding: 1em;
        background-color: rgba(255, 255, 255, .75);
        text-align: center;
    }
    .project-preview h2 {
        font-size: 1em;
    }

    .project-preview h3 {
        font-size: .8em;
    }
}

/*For smartphones*/
@media only screen and (max-width : 765px) {
    .project-preview {
        flex-basis: 98%;
    }

    .project-preview h3, .project-preview h2 {
        display: none;
    }

}